<template>
  <!-- 宠物事项详情页面 -->
  <moe-page title="宠物事项详情">
    <moe-describe-list title="宠物事项信息">
      <moe-describe-item label="提醒事项名称">{{ matter.name }} </moe-describe-item>
      <moe-describe-item label="提醒说明">{{ matter.name }} </moe-describe-item>
      <moe-describe-item label="提醒主图">
        <moe-image :src="matter.img" width="100px" height="100px" />
      </moe-describe-item>
    </moe-describe-list>
  </moe-page>
</template>

<script>
export default {
  name: 'PetMatterDetail',
  data() {
    return {
      matter: {}, //提醒事项信息
    };
  },
  created() {
    //获取宠物事项详情
    this.getMatterDetail();
  },
  methods: {
    /**
     * 获取宠物事项详情
     **/
    getMatterDetail() {
      const id = this.$route.query.id;
      // 调用获取宠物事项详情API
      this.$moe_api.PET_API.petMatterDetail({ id }).then((data) => {
        if (data.code == 200) {
          let m = data.result;

          // 回显宠物分类
          this.matter = {
            name: m.name, //事项名称
            remarks: m.remarks, //事项说明
            img: m.img, //事项主图
          };
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },

    /**
     * 提交提醒事项信息
     **/
    matterInfoSubmit() {
      // 表单校验
      this.$refs['matterForm'].validate(() => {
        this.matterLoad = true;
        this.$moe_api.PET_API.petMatterAdd(this.matter)
          .then((data) => {
            if (data.code == 200) {
              this.$moe_msg.success('添加成功');
              this.$router.go(-1);
            } else {
              this.$moe_msg.error(data.message);
            }
          })
          .finally(() => {
            this.matterLoad = false;
          });
      });
    },
  },
};
</script>
